import { LocaleType } from '@types';

import { routesConstants } from '@constants';

export const footerNavigation = {
  items: [
    {
      id: 'lobby',
      title: '_l.footer.navigation.items.lobby',
      link: routesConstants.LOBBY,
    },
    {
      id: 'tournaments',
      title: '_l.footer.navigation.items.tournaments',
      link: routesConstants.TOURNAMENTS,
    },
    {
      id: 'puzzles',
      title: '_l.footer.navigation.items.puzzles',
      link: routesConstants.PUZZLES,
    },
    {
      id: 'masterclasses',
      title: '_l.footer.navigation.items.masterclasses',
      link: routesConstants.MASTERCLASSES,
    },
    {
      id: 'players',
      title: '_l.footer.navigation.items.players',
      link: routesConstants.PLAYERS,
    },
    {
      id: 'clubs',
      title: '_l.footer.navigation.items.clubs',
      link: routesConstants.COMMUNITY,
    },
    {
      id: 'broadcasts',
      title: '_l.footer.navigation.items.broadcasts',
      link: routesConstants.BROADCAST_LOBBY,
    },
    {
      id: 'journal',
      title: '_l.footer.navigation.items.journal',
      href: 'https://chessarena.com/news/',
    },
    {
      id: 'shop',
      title: '_l.footer.navigation.items.shop',
      href: 'https://shop.worldchess.com/',
    },
    {
      id: 'coaches',
      title: '_l.footer.navigation.items.coaches',
      href: 'https://coaches.chessarena.com',
    },
    {
      id: 'pricing',
      title: '_l.footer.links.items.pricing',
      link: routesConstants.PRICING,
    },
    {
      id: 'support',
      title: '_l.footer.links.items.support',
      href: 'https://support.chessarena.com/en',
    },
    {
      id: 'release_notes',
      title: '_l.footer.links.items.release_notes',
      href:
        process.env.NEXT_PUBLIC_ENV === 'master'
          ? 'https://releasenotes.chessarena.com'
          : 'https://news.intercom.com/chess-arena',
    },
    {
      id: 'fair_play_policy',
      title: '_l.footer.links.items.fair_play_policy',
      href: 'https://support.chessarena.com/en/articles/6729039-arena-fair-play-policy',
    },
    {
      id: 'privacy_policy',
      title: '_l.footer.links.items.privacy_policy',
      href: 'https://support.chessarena.com/en/articles/6902938-privacy-policy',
    },
    {
      id: 'terms_of_use',
      title: '_l.footer.links.items.terms_of_use',
      href: 'https://support.chessarena.com/en/articles/6740851-terms-and-conditions',
    },
    {
      id: 'api',
      title: '_l.footer.links.items.api',
      href: 'https://worldchess.com/',
    },
  ],
};

export const footerSocialNetworks = {
  items: [
    {
      id: 'youtube',
      title: '_l.footer.social_networks.items.youtube',
      href: 'https://www.youtube.com/@theworldchess',
    },
    {
      id: 'tiktok',
      href: 'https://www.tiktok.com/@world_chess_',
    },
    {
      id: 'twitter',
      href: 'https://twitter.com/theworldchess',
    },
    {
      id: 'discord',
      href: 'https://discord.gg/VKMr5gvbcd',
    },
    {
      id: 'facebook',
      href: 'https://www.facebook.com/FideOnlineArena',
    },
    {
      id: 'instagram',
      href: 'https://www.instagram.com/fideonlinearena',
    },
  ],
};

export const footerCopyrights = {
  worldchess: {
    href: 'https://worldchess.com/',
  },
  lse: {
    href: 'https://www.londonstockexchange.com/stock/CHSS/world-chess-plc/company-page',
  },
};

export const footerAppsDownload = {
  apple:
    'https://zljq.adj.st?adj_t=1ajb8948&adj_adgroup=Footer&adj_engagement_type=fallback_click',
  google:
    'https://app.adjust.com/1agsj3yn?campaign=Website&adgroup=Footer&engagement_type=fallback_click',
};

export const footerLocalization = {
  items: [
    {
      id: LocaleType.EN,
      title: '_l.localization.english',
      abbr: 'EN',
    },
    {
      id: LocaleType.RU,
      title: '_l.localization.russian',
      abbr: 'RU',
    },
    {
      id: LocaleType.KZ,
      title: '_l.localization.kazakh',
      abbr: 'KZ',
    },
  ],
};

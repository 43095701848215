import { useMemo } from 'react';
import Link from 'next/link';

import { RenderLinkParams } from '@chessarena/components/lib/utils/renderLinkTypes';
import { useApplicationContext } from '@application';
import {
  footerAppsDownload,
  footerCopyrights,
  footerSocialNetworks,
  footerNavigation,
} from './_constants';

export const useCustomFooterData = () => {
  const { localization: l } = useApplicationContext();

  const renderLink = (params: RenderLinkParams) => {
    const { link, children } = params;

    return <Link href={link}>{children}</Link>;
  };

  const navigation = useMemo(
    () => ({
      items: footerNavigation.items.map((item) => ({
        ...item,
        title: l?.footer.navigation.items[item.id],
      })),
      renderLink,
    }),
    [l?.footer.navigation.items]
  );

  const socialNetworks = useMemo(
    () => ({
      ...footerSocialNetworks,
      items: footerSocialNetworks.items.map((item) => ({
        ...item,
        title: l?.footer.social_networks.items[item.id],
      })),
    }),
    [l?.footer.social_networks.items]
  );

  const copyrights = useMemo(
    () => ({
      ...footerCopyrights,
      worldchess: {
        ...footerCopyrights.worldchess,
        copyright: l?.footer.copyrights.worldchess.copyright,
        title: l?.footer.copyrights.worldchess.title,
      },
      lse: {
        ...footerCopyrights.lse,
        title: l?.footer.copyrights.lse.title,
      },
    }),
    [l?.footer.copyrights]
  );

  return {
    navigation,
    socialNetworks,
    copyrights,
    appsDownload: footerAppsDownload,
  };
};

import React, { memo } from 'react';

import Footer from '@chessarena/components/lib/layout/Footer';

import { useCustomFooterData } from './_useCustomFooterData.hook';

export const CustomFooter: React.FC = memo(function CustomFooter() {
  const { navigation, socialNetworks, copyrights, appsDownload } =
    useCustomFooterData();

  return (
    <>
      <Footer
        navigation={navigation}
        socialNetworks={socialNetworks}
        copyrights={copyrights}
        appsDownload={appsDownload}
      />
    </>
  );
});
